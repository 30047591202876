import React from "react";
import PropTypes from "prop-types";
import jpDataSet from "japanese-kana-dataset";
import styles from "./KanaBox.module.css";

const KanaBox = ({ type = "hiragana" }) => (
  <div className={styles.container}>
    <div className={styles.titleBox}>
      {type === "hiragana" ? "Hiragana (ひらがな)" : "Katakana (カタカナ)"}
    </div>
    <div className={styles.gridBox}>
      {jpDataSet[type]
        .getMonographs()
        .kana.filter((_) => !_.diacritic)
        .map((_) => (
          <div
            className={styles.kana}
            style={{
              gridRow: `row-${_.vowel} / row-${_.vowel}-end`,
              gridColumn: `col-${_.consonant} / col-${_.consonant}-end`,
            }}
          >
            <span>{_.kana}</span>
            <span>{_.romaji}</span>
          </div>
        ))}
    </div>
  </div>
);

KanaBox.propTypes = {
  type: PropTypes.oneOf(["hiragana", "katakana"]),
};

export default KanaBox;
