import React from 'react';
import KanaBox from './components/KanaBox';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.container}>
      <KanaBox type="hiragana" />
      <KanaBox type="katakana" />
    </div>
  );
}

export default App;
